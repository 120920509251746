import '../lib/sortable';
import '../lib/svelte';
import '../stylesheets/application.scss';
import '@hotwired/turbo'

import * as ActiveStorage from '@rails/activestorage'

ActiveStorage.start()

document.addEventListener('DOMContentLoaded', () => {
  const notice = document.querySelector('#nav .notice');
  if (notice) {
    const datetime = localStorage.getItem('notice-datetime');
    const now = new Date()
    const duration = 7 * 24 * 60 * 60 * 1000;
    if (datetime && now - Date.parse(datetime) < duration)
      return;
    notice.style.display = 'block';
    notice.querySelector('.close').addEventListener('click', () => {
      notice.style.display = '';
      localStorage.setItem('notice-datetime', new Date().toISOString());
    });
  }
});
