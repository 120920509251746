<script>
  import Routes from '../lib/routes';
  import SearchItem from './search-item';

  export let type, value;

  let wrapper;
  let popup_visible = false;
  let items = [];
  let current_value = value || '';

  $: current_value != '' && load_items();

  function click_outside(event) {
    if (!wrapper.contains(event.target))
      popup_visible = false;
  }

  function show_popup() {
    popup_visible = true;
  }

  function load_items() {
    if (current_value.length < 3) {
      items = [];
      return;
    }

    let v;
    switch (type) {
      case 'product':
        v = Routes.product_api({ q: current_value });
        break;
      case 'transmission':
        v = Routes.transmission_api({ q: current_value });
        break;
    }
    v && v.then(res => items = res.data.items || [] );
  }

  function item_path(item = null) {
    let path = '';

    switch (type) {
      case 'product':
        path = Routes.product_catalog_path();
        const options = item ? {q: item.code} : {};
        if (item && item.catalog_id)
          options.c = item.catalog_id;
        return item ? build_url(path, options) : path;
      case 'transmission':
        path = Routes.transmission_catalog_path();
        return item ? build_url(path, {q: item.code}) : path;
    }
  }

  function build_url(path, url_options) {
    return `${path}?${object_to_url_options(url_options)}`;
  }

  // Does not work with array and nested objects!
  function object_to_url_options(object) {
    const u = new URLSearchParams();
    for (const key in object)
      u.append(key, object[key]);
    return u.toString();
  }

  function redirect(item) {
    window.Turbo.visit(item_path(item));
  }
</script>

<style>
  form {
    position: relative;
  }

  .popup {
    background-color: white;
    border: 1px solid black;
    display: none;
    max-height: 300px;
    min-height: 20px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .popup.visible {
    display: block;
  }

  .popup .item {
    margin: 0;
    padding: 0.2em;
    color: black;
    cursor: pointer;
    background-color: white;
  }

  .popup .item:hover {
    background-color: rgb(2, 2, 153) !important;
    color: white;
  }

  .popup .item:nth-child(even) {
    background-color: #eee;
  }
</style>

<svelte:body on:click={click_outside}></svelte:body>

<form method="get" action={item_path()} bind:this={wrapper}>
  <label for="q" on:click={show_popup}>
    {#if type == 'product'}
      Поиск детали по <b>артикулу</b> <i>(part number)</i>
    {:else if type == 'transmission'}
      Поиск детали по <b>трансмиссии</b> <i>(transmission)</i>
    {/if}
  </label>
  <div class="query-box">
    <input
      type="text" name="q" bind:value={current_value} class="query" autocomplete="off"
      on:click={show_popup} on:change={show_popup} on:paste={show_popup}
    />
    <input type="image" src="/i/search.png" title="Найти" alt="Найти" />
  </div>
  <div class="popup" class:visible={popup_visible && items.length > 0}>
    {#each items as item, index (index)}
      <p class="item" on:click={() => redirect(item)}>
        <SearchItem
          title={`${item.code} ${item.desc}`} search={current_value} highlight={item.code}
        />
        {#if item.catalog}<span class="catalog">({item.catalog})</span>{/if}
      </p>
    {/each}
  </div>
</form>
