import { writable } from 'svelte/store';

export function store() {
  return writable(null);
}

export function new_index(images, cycle = true) {
  const count = images.length;

  return {
    next: function(current) {
      if (count == 1)
        return null;

      let val = current + 1;
      if (val >= count)
        val = cycle ? 0 : null;
      return val;
    },
    prev: function(current) {
      if (count == 1)
        return null;

      let val = current - 1;
      if (val < 0)
        val = cycle ? count - 1 : null;
      return val;
    }
  };
}
