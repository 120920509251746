<script>
  import { createEventDispatcher } from 'svelte';

  export let type; // zoom, close, prev, next

  const dispatch = createEventDispatcher();
</script>

<style>
  .button {
    background: none;
    border: 0;
    color: white;
    cursor: pointer;
    padding: 0;
    position: relative;
  }

  .button::before {
    background-color: #ccc0;
    border-radius: 20px;
    content: '';
    height: 30px;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    width: 30px;
  }

  .button:hover::before {
    background-color: #ccc9;
  }
</style>

<button
  type="button"
  class={`button ${type}`}
  on:click|stopPropagation={() => dispatch('click')}
>
  <svg
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="20px"
    height="20px"
  >
    {#if type == 'zoom'}
      <path
        d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37
        28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625
        31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5
        C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6
        28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"
        stroke="white"
        stroke-width="2"
      />
    {:else if type == 'close'}
      <path
        d="M 3 3 L 47 47 M 3 47 L 47 3 Z"
        stroke="white"
        stroke-width="4"
      />
    {:else if type == 'prev'}
      <path
        d="M 32 3 L 16 25 L 32 47 L 18 25 Z"
        stroke="white"
        stroke-width="4"
      />
    {:else if type == 'next'}
      <path
        d="M 18 3 L 34 25 M 18 47 L 32 25 Z"
        stroke="white"
        stroke-width="4"
      />
    {/if}
  </svg>
</button>
