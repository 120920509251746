<script>
  import { createEventDispatcher } from 'svelte';

  export let image = {}, maximized = false;

  const dispatch = createEventDispatcher();

  let image_node = {};

  export const get_image_node = () => image_node;
</script>

<style>
  .image {
    max-height: calc(100vh - var(--image-height-exclude, 0px));
    max-width: calc(100vw - 40px);
  }

  .image.maximized {
    overflow: scroll;
  }

  .image img {
    height: 100%;
    margin-bottom: -4px; /* hide empty space below the image */
    max-height: inherit;
    width: 100%;
  }

  .image.maximized img {
    height: auto;
    max-height: 100%;
    width: auto;
  }
</style>

<div class="image" class:maximized={maximized}>
  <img
    {...image}
    class:maximized={maximized}
    on:click|stopPropagation={() => dispatch('zoom')}
    bind:this={image_node}
  />
</div>
