<script>
  import { createEventDispatcher } from 'svelte';

  export let images = [];

  const dispatch = createEventDispatcher();

  function click(index) {
    dispatch('click', index);
  }
</script>

<style>
  .gallery {
    display: flex;
    justify-content: center;
  }

  .thumbnails {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  .gallery .image {
    margin-bottom: 10px;
    margin-right: 2px;
  }

  .gallery .image:hover {
    cursor: pointer;
  }

  .gallery img {
    border: 1px solid #eee;
    max-width: 100%;
  }

  .image__col1 { flex-basis: calc(100% - 2px); }
  .image__col2 { flex-basis: calc(50% - 4px); }
</style>

<div class="gallery">
  <div class="thumbnails">
    {#each images as img, i (img.msrc || img.src)}
      <div class={`image ${images.length % 2 == 1 ? "image__col1" : "image__col2"}`}>
        <img
          {...img}
          src={img.msrc || img.src}
          on:click={() => click(i)}
        />
      </div>
    {/each}
  </div>
</div>
