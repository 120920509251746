<script>
  import Routes from '../lib/routes';

  export let type;

  const promises = {
    analogs: () => Routes.analogs_admin_api({q: value}),
  };

  let wrapper;
  let value = '';
  let suggestions = [];
  let show = false;
  let selected = null;

  $: {
    if (value === '') {
      suggestions = [];
    } else {
      load_suggestions();
      show_suggestions();
    }
  }

  function load_suggestions() {
    const promise = promises[type];
    if (!promise) throw new Error(`Unknown type: ${type}`);
    promise().then(res => {
      suggestions = res.data;
    }).catch(res => {
      console.log(res.data.errors);
      alert('Произошла ошибка');
    });
  }

  function show_suggestions() {
    show = true;
  }

  function hide_suggestions() {
    show = false;
  }

  function select_item(item) {
    hide_suggestions();
    selected = item;
  }

  function click_outside(event) {
    if (!wrapper.contains(event.target))
      hide_suggestions();
  }

  function deselect_item() {
    selected = null;
    show_suggestions();
  }
</script>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .box {
    background-color: white;
    border: 1px solid black;
    border-bottom-width: 2px;
    color: black;
    left: 0;
    max-height: 400px;
    overflow-y: auto;
    padding: 0.4em;
    position: absolute;
    right: 0;
    top: 26px;
  }
  .item {
    border-radius: 2px;
    padding: 0.6em;
  }
  .item:hover {
    background-color: #6495ff;
    color: white;
  }
  .item + .item {
    border-top: 1px solid #ccc;
  }
  span {
    display: block;
  }
  .title {
    font-size: larger;
  }
  p {
    font-style: italic;
    margin: 0;
    padding: 0.6em;
  }
  .selected-item {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  .content {
    margin-right: 1em;
  }
  .selected-item .item {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
</style>

<svelte:body on:click={click_outside}></svelte:body>

<div class="wrapper" bind:this={wrapper}>
  {#if selected}
    <div class="selected-item">
      <div class="item">
        <div class="content">
          {#if type == 'analogs'}
            <span class="title">{selected.code} ({selected.desc})</span>
            <span>Каталог: {selected.catalog_name}</span>
          {/if}
        </div>
        <button type="button" class="btn" on:click={deselect_item} title="Убрать">x</button>
      </div>
    </div>
    <input type="hidden" name="object_id" value={selected.id} />
  {:else}
    <input type='text' bind:value={value} class="no-min-width" on:focus={show_suggestions} />
  {/if}

  {#if show}
    <div class="box">
      {#each suggestions as item (item.id)}
        <div class="item" on:click={() => select_item(item)}>
          {#if type == 'analogs'}
            <span class="title">{item.code} ({item.desc})</span>
            <span>Каталог: {item.catalog_name}</span>
          {/if}
        </div>
      {:else}
        <p>Записи не найдены</p>
      {/each}
    </div>
  {/if}
</div>
