<script>
  import {tick} from 'svelte';
  import Routes from '../lib/routes';

  export let id, type, title, value;

  const promises = {
    catalogs:      () => Routes.update_admin_catalog(id, {}, {catalog: {name: new_value}}),
    makes:         () => Routes.update_admin_make(id, {}, {make: {name: new_value}}),
    manufacturers: () => Routes.update_admin_manufacturer(id, {}, {manufacturer: {name: new_value}}),
  }

  let in_edit = false, new_value = '', input_node;

  $: new_value = value;

  async function handle_edit() {
    in_edit = true;
    await tick();
    input_node.focus();
    input_node.setSelectionRange(0, new_value.length);
  }

  function handle_submit() {
    const promise = promises[type];
    if (!promise) throw new Error(`Unknown type: ${type}`);
    promise().then(_res => {
      in_edit = false;
    }).catch(res => {
      console.log(res.data.errors);
      alert('Произошла ошибка');
    });
  }
</script>

<style>
  form input {
    display: inline-block;
  }
  input[type="text"] {
    margin-right: 0.2em;
  }
  span {
    border-bottom: 1px dashed #555;
  }
</style>

{#if in_edit}
  <form on:submit|preventDefault={handle_submit}>
    <input type="text" bind:value={new_value} bind:this={input_node} />
    <input type="submit" value="ok" />
  </form>
{:else}
  <span title={title} on:click={handle_edit}>{new_value}</span>
{/if}
