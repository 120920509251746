<script>
  import Routes from '../lib/routes';

  export let id, type, title, url;

  const promises = {
    analogs:              () => Routes.delete_admin_analog(id),
    catalogs:             () => Routes.delete_admin_catalog(id),
    groups:               () => Routes.delete_admin_group(id),
    images:               () => Routes.admin_image(id),
    makes:                () => Routes.delete_admin_make(id),
    manufacturers:        () => Routes.delete_admin_manufacturer(id),
    models:               () => Routes.delete_admin_model(id),
    products:             () => Routes.delete_admin_product(id),
    product_applications: () => Routes.admin_product_application(...id.split(',')),
    product_analogs:      () => Routes.admin_product_analog(...id.split(',')),
    transmissions:        () => Routes.delete_admin_transmission(id),
  };

  function handle_click() {
    if (!confirm("Удалить запись?")) return;

    const promise = promises[type];
    if (!promise) throw new Error(`Unknown type: ${type}`);
    promise().then(_res => {
      if (url)
        window.Turbo.visit(url);
      else
        document.location.reload();
    }).catch(res => {
      console.log(res.data.errors);
      alert('Произошла ошибка');
    });
  }
</script>

<button type="button" class="btn" on:click={handle_click}>{title}</button>
