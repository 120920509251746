<script>
  import {recalc} from './search-item__parts';

  export let title, search, highlight;

  let parts = [];

  $: (title && search) && recalc_parts();

  function recalc_parts() {
    parts = recalc(title, search, highlight);
  }
</script>

<style>
  .highlight {
    font-weight: bold;
  }

  .match {
    color: #FF1774;
    font-weight: bold;
  }
</style>

<span>
  {#each parts as part, index (index)}
    {#if part.match}
      <span class="match" class:highlight={part.highlight}>{part.text}</span>
    {:else}
      <span class:highlight={part.highlight}>{part.text}</span>
    {/if}
  {/each}
</span>
