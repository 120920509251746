<script>
  import { afterUpdate, createEventDispatcher, onDestroy, onMount } from 'svelte';
  import Button from './button'; // SVG
  import Image from './image';

  export let image = {};
  export let next;
  export let prev;

  const dispatch = createEventDispatcher();

  let maximized = false;
  let show_zoom = false;
  let image_component;

  // Reset state after changing image.
  $: image, (maximized = false);

  onMount(() => {
    // Hide scroll bars.
    document.body.style.overflow = 'hidden';
  });

  afterUpdate(() => {
    // Show zoom button if image scale can be changed.
    show_zoom = maximized || image_scaled();
  });

  onDestroy(() => {
    // Restore scroll bars.
    document.body.style.overflow = '';
  });

  function next_image() {
    dispatch('change', next);
  }

  function prev_image() {
    dispatch('change', prev);
  }

  function close() {
    dispatch('close');
  }

  function zoom_out() {
    maximized = false;
  }

  function zoom_in() {
    maximized = true;
  }

  function image_scaled() {
    const image_node = image_component ? image_component.get_image_node() : {};
    return (
      image_node.naturalWidth !== image_node.width ||
      image_node.naturalHeight !== image_node.height
    );
  }
</script>

<style>
  :root {
    --toolbar-margin-bottom: 8px;
    --toolbar-margin-top: 13px;
    --toolbar-content-height: 24px;
    --image-margin-bottom: 16px;
    --image-height-exclude: calc(
      var(--toolbar-margin-bottom) +
      var(--toolbar-margin-top) +
      var(--toolbar-content-height) +
      var(--image-margin-bottom)
    );
  }

  .wrapper {
    align-items: center;
    background-color: #000C;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .container {
    max-height: 100vh;
    max-width: 100vw;
  }

  .toolbar {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: var(--toolbar-content-height);
    margin-bottom: var(--toolbar-margin-bottom);
    margin-top: var(--toolbar-margin-top);
  }

  .toolbar :global(.zoom) {
    margin-right: 12px;
  }

  .toolbar :global(.close) {
    margin-right: 7px;
  }

  .image-with-controls {
    align-items: center;
    display: flex;
    margin-bottom: var(--image-margin-bottom);
  }

  .image-with-controls :global(.button) {
    margin: 0 6px;
  }

  .image-with-controls :global(.button::before) {
    background-color: #3f91dd88;
  }
</style>

<div class="wrapper" on:click={close}>
  <div class="container">
    <div class="toolbar">
      {#if show_zoom}<Button type="zoom" on:click={maximized ? zoom_out : zoom_in} />{/if}
      <Button type="close" on:click={close} />
    </div>
    <div class="image-with-controls">
      {#if prev !== null}<Button type="prev" on:click={prev_image} />{/if}
      <Image
        image={image}
        maximized={maximized}
        bind:this={image_component}
        on:zoom={maximized ? zoom_out : zoom_in}
      />
      {#if next !== null}<Button type="next" on:click={next_image} />{/if}
    </div>
  </div>
</div>
