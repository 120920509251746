// Highlight product code or transmission code.
function get_highlight_parts(string, substring) {
  const result = [];

  if (string.substr(0, substring.length) == substring) {
    result.push({start: 0, len: substring.length, match: true});
    result.push({start: substring.length, len: string.length - substring.length, match: false});
  } else {
    result.push({start: 0, len: string.length, match: false});
  }

  return result;
}

// Find substrings in string.
function get_search_parts(string, substring) {
  let index      = 0;
  let prev_index = 0;

  const result = [];

  do {
    index = string.indexOf(substring, prev_index);
    if (index >= 0) {
      if (index > prev_index) {
        result.push({start: prev_index, len: index - prev_index, match: false});
      }
      result.push({start: index, len: substring.length, match: true});
      prev_index = index + substring.length;
    } else {
      result.push({start: prev_index, len: string.length - prev_index, match: false});
    }
  } while (index >= 0);

  return result;
}

// Sort array of numbers.
function sort_numbers(numbers) {
  return numbers.sort((a, b) => a < b ? -1 : (a == b ? 0 : 1));
}

// Remove duplicates from sorted array.
function uniq_sorted(array) {
  return array.filter((v, i, a) => v < a[i + 1] || typeof(a[i + 1]) == 'undefined');
}

// Get indices for string parts.
function combine_indices(arrays_of_parts) {
  const indices = arrays_of_parts.map(array => {
    return array.map(part => part.start);
  }).flat(1);

  return uniq_sorted(sort_numbers(indices));
}

// Split string by indices in specified parts.
function build_parts(string, indices, highlight_parts, search_parts) {
  const result = [];

  // Combine results.
  let hp, sp, prev = 0;
  for (const i of indices) {
    if (i == 0)
      continue;
    hp = highlight_parts.find(part => part.start <= i && part.start + part.len >= i);
    sp = search_parts.find(part => part.start <= i && part.start + part.len >= i);
    result.push({match: sp.match, highlight: hp.match, text: string.substring(prev, i)});
    prev = i;
  }

  const last = indices[indices.length-1];
  if (last != string.length - 1)
    result.push({match: false, highlight: false, text: string.substring(last)});

  return result;
}

export function recalc(title, search, highlight) {
  const ttl             = title.toLowerCase();
  const highlight_parts = get_highlight_parts(ttl, highlight.toLowerCase());
  const search_parts    = get_search_parts(ttl, search.toLowerCase());
  const indices         = combine_indices([highlight_parts, search_parts]);

  return build_parts(title, indices, highlight_parts, search_parts);
}
