<script>
  import Routes from '../lib/routes';
  export let code, added, textTitle, buttonTitle;

  let value = 1;

  function addItem() {
    const data = {code: code, add: value}; //, authenticity_token
    Routes.create_cart({}, data).then(res => {
      document.querySelector('.cart-widget-wrapper').innerHTML = res.data;
      added = parseInt(added) + parseInt(value);
    });
  }
</script>

<style>
  .dot {
    background: #FF1774;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    height: 16px;
    line-height: 14px;
    margin-left: 12px;
    margin-top: 4px;
    position: absolute;
    text-align: center;
    text-shadow: none;
    width: 16px;
  }

  a:hover {
    text-decoration: none !important;
  }

  :global(#catalogs-product .dot) {
    margin-top: 10px;
  }
</style>

<input type="text" class="qty" autocomplete="off" bind:value={value} title={textTitle} />
<a class="cart24" href="#" title={buttonTitle} on:click|preventDefault={addItem}>&nbsp;</a>
{#if parseInt(added) > 0}
  <a href="/cart" class="dot" title={`${added} шт. в корзине`}>{added}</a>
{/if}
