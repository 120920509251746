<script>
  import Thumbnails from './thumbnails';
  import LightBox from './light-box';
  import { new_index, store } from './series';

  // [{src: String, msrc: String, alt: String, ...}, ...]
  // src: Original image path (big picture).
  // msrc: Thumbnail image path (small picture).
  export let images = [];

  export let cycle = true;

  let images_store = store();
  let images_index = new_index(images, cycle);

  $: {
    images_store = store();
    images_index = new_index(images, cycle);
  }
</script>

<Thumbnails
  images={images}
  on:click={event => { images_store.set(event.detail) }}
/>

{#if $images_store !== null && images[$images_store]}
  <LightBox
    image={images[$images_store]}
    next={images_index.next($images_store)}
    prev={images_index.prev($images_store)}
    on:change={event => { images_store.set(event.detail) }}
    on:close={() => { images_store.set(null) }}
  />
{/if}
