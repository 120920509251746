// Sorted by key [A-Z]
export default {
  'add-to-cart': require('../components/add-to-cart.svelte').default,
  'delete-button': require('../components/delete-button.svelte').default,
  editable: require('../components/editable.svelte').default,
  'input-with-suggestions': require('../components/input-with-suggestions.svelte').default,
  'product-images': require('../components/product-images.svelte').default,
  'search-box': require('../components/search-box.svelte').default,
  'transmission-images': require('../components/transmission-images.svelte').default,
  'yandex-map': require('../components/yandex-map.svelte').default,
  // "user-button": require('components/buttons/user-button').default,
};
