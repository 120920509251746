<script>
  import Gallery from './gallery/gallery';

  export let images = '[]', imageTitle = '';

  let parsed_input = [];

  $: parsed_input = JSON.parse(images).map(x => ({src: x, alt: imageTitle}));
</script>

<style>
  :global(.gallery) {
    margin: 0 0 0 2px;
  }
</style>

<Gallery images={parsed_input} />
