import Routes from './routes';
import {ready} from './dom';

let current, traps = [];

function elem(tag, options) {
  const node = document.createElement(tag);
  for (const key in options) {
    const val = options[key];
    if (val !== null && typeof val === 'object') {
      if (node[key] === undefined)
        node[key] = {};
      for (const k2 in val)
        node[key][k2] = val[k2];
    } else {
      node[key] = val;
    }
  }
  return node;
}

function mouse_down(event) {
  if (event.button != 0) return; // left mouse button

  event.preventDefault();
  document.body.style.cursor = 'move';

  current = event.target.closest('.thumbnail');

  const blocks = document.querySelectorAll('.thumbnail');
  if (!blocks.length) return;

  const trap = elem(
    'div',
    {
      className: 'trap',
      dataset: {pos: blocks.length}
    }
  );
  const parent = blocks[0].parentElement;
  traps = [trap];
  parent.appendChild(trap);

  let i = 0;
  for (const block of blocks) {
    const t = trap.cloneNode(true);
    t.dataset.pos = i++;
    parent.insertBefore(t, block);
    traps.push(t);
  }
}

function mouse_move(event) {
  if (!current) return;

  const target = event.target;
  for (const t of traps)
    t.style.borderColor = 'orange';
  if (target.className === 'trap')
    target.style.borderColor = 'cornflowerblue';
}

function remove_traps() {
  if (traps.length) {
    const parent = traps[0].parentElement;
    for (const t of traps)
      parent.removeChild(t);
    traps = [];
  }
  current = null;
  document.body.style.cursor = 'auto';
}

function mouse_up(event) {
  const target = event.target;
  if (target.className !== 'trap') {
    remove_traps();
    return;
  }

  const ids = [...document.querySelectorAll('.thumbnail')].map(node => node.dataset.id);
  const current_id = current.dataset.id;
  const current_pos = ids.indexOf(current_id);
  const new_pos = target.dataset.pos;
  const max = ids.length;
  const result =
    current_pos < new_pos ? ids.slice(0, current_pos).concat(
      ids.slice(current_pos + 1, new_pos), [current_id], ids.slice(new_pos, max)
    ) : ids.slice(0, new_pos).concat(
      [current_id], ids.slice(new_pos, current_pos), ids.slice(current_pos + 1, max)
    );
  remove_traps();
  // console.warn(ids, current_id, current_pos, new_pos, result);
  Routes.sort_admin_images({}, {order: result.join(',')}).then(() => document.location.reload());
}

ready(() => {
  const images = document.querySelectorAll('.thumbnail img');
  if (!images.length) return;
  for (const item of images) {
    item.removeEventListener('mousedown', mouse_down);
    item.addEventListener('mousedown', mouse_down);
  }
  window.removeEventListener('mousemove', mouse_move);
  window.addEventListener('mousemove', mouse_move);
  window.removeEventListener('mouseup', mouse_up);
  window.addEventListener('mouseup', mouse_up);
});
