<script>
  import LightBox from './gallery/light-box';
  import { new_index, store } from './gallery/series';

  export let images = '[]', imageTitle = '';

  let parsed_input = [];
  let images_store = store();
  let images_index = new_index([], true);

  $: parsed_input = JSON.parse(images).map(x => ({src: x, alt: imageTitle}));

  $: {
    images_store = store();
    images_index = new_index(parsed_input, true);
  }
</script>

<style>
  .gallery {
    float: left;
    margin-right: 20px;
    width: 196px;
  }

  .thumbnails {
    display: flex;
    flex-flow: row wrap;
  }

  .image {
    height: auto !important;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .image:hover {
    opacity: 0.8;
    filter: grayscale(0.5) blur(0.5px);
  }

  .gallery .image:first-child {
    border: 1px solid #000;
    width: 192px;
  }

  .gallery .image:not(:first-child) {
    border: 1px solid #ccc;
    float: left;
    margin-right: 2px;
    width: 45px;
  }

  .dummy {
    background-image: url(/i/pinstripe.png);
    background-repeat: repeat-x;
    border: 1px solid #ccc;
    height: 192px;
    width: 192px;
  }
</style>

<div class="gallery">
  <div class="thumbnails">
    {#each parsed_input as img, i (img.msrc || img.src)}
      <img
        {...img}
        src={img.msrc || img.src}
        class="image"
        on:click={() => images_store.set(i)}
      />
    {:else}
      <div class="dummy"></div>
    {/each}
  </div>
</div>

{#if $images_store !== null && parsed_input[$images_store]}
  <LightBox
    image={parsed_input[$images_store]}
    next={images_index.next($images_store)}
    prev={images_index.prev($images_store)}
    on:change={event => { images_store.set(event.detail) }}
    on:close={() => { images_store.set(null) }}
  />
{/if}
